<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-eow-products-categories' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Categories</v-btn
        >
      </v-row>
      <v-row no-gutters align="center" class="mt-0">
        <h1>{{ category.name }}</h1>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Basic Information</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.categoryDialog.openForm(category)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Category</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Description</th>
                  <td>{{ category.description }}</td>
                </tr>
                <tr>
                  <th>Slug</th>
                  <td>{{ category.slug }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <category-dialog ref="categoryDialog" />
  </div>
</template>

<script>
import CategoryDialog from "./components/CategoryForm.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: {
    CategoryDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Categories",
          disabled: false,
          to: { name: "module-eow-products-categories" },
          exact: true,
        },
      ],
      searchTerm: "",
      fieldsMessage: {
        message: null,
      },
      fieldsSale: {
        early_access: false,
        general_sale: false,
      },
      loading: false,
      loadingSale: false,
      errors: {},
      searchFilters: {
        menu: {
          open: false,
          closeOnClick: true,
          closeOnContentClick: false,
        },
      },
    };
  },

  mounted: function () {},

  computed: {
    category() {
      return this.$store.getters["eow/productsStore/category"];
    },

    tableHeaders() {
      return [
        { text: "Customer", value: "customer" },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  // created() {
  //   this.breadcrumbs.push({
  //     text: this.category.name,
  //     disabled: true,
  //   });
  // },

  methods: {
    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        categoryId: this.$route.params.categoryId,
        fields: this.fieldsMessage,
      };

      // payload.fields.type = "message";

      this.$store
        .dispatch("eow/categorysStore/saveCategoryEmail", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.loading = false;
      this.isEditing = true;
    },

    resetFormSale: function () {
      this.loadingSale = false;
      this.isEditing = true;
    },
  },
};
</script>
